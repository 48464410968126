// npm
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
import Splide from '@splidejs/splide';
import '@splidejs/splide/css/core';

// css
import '../../scss/pages/home2024.scss';

// utils
import foundation from '../_utils/foundation';
foundation();

// libs
import MouseStalker from '../_libs/MouseStalker';
import Ease from '../_utils/Ease';

document.addEventListener('DOMContentLoaded', () => {
	const $mv = document.querySelector('.js-mv');
	if ($mv) {
		const DELAY = 6000;

		const $$slide = $mv.querySelectorAll('.js-mv-slider .splide__slide');

		/**
		 * @type {HTMLElement}
		 */
		const $slider = $mv.querySelector('.js-mv-slider');
		$slider.style.setProperty('--_duration', `${DELAY}ms`);

		const mvSplide = new Splide('.js-mv-slider', {
			type: 'fade',
			arrows: false,
			pagination: false,
			rewind: true,
			speed: 2000,
			easing: 'cubic-bezier(0.19, 1, 0.22, 1)',
			autoplay: true,
			pauseOnHover: false,
			pauseOnFocus: false,
			interval: DELAY,
		});

		const $progress = $mv.querySelector('.js-mv-progress');
		const updateProgress = (index) => {
			const $target = $$slide[index];
			const $targetId = $target.getAttribute('data-work-id');

			const $progressTarget = $progress.querySelector(
				`[data-progress-id="${$targetId}"]`
			);

			$progress.querySelectorAll('.is-active').forEach((el) => {
				el.setAttribute('style', `--_duration: 400ms`);
				el.classList.remove('is-active');
			});

			$progressTarget.setAttribute('style', `--_duration: ${DELAY}ms`);
			$progressTarget.classList.add('is-active');
		};

		// バーの幅を更新する

		mvSplide.on('move', function () {
			updateProgress(mvSplide.index);
		});

		mvSplide.mount();

		// 初回実行
		setTimeout(() => {
			updateProgress(0);
		}, 100);
	}

	const $pickup = document.querySelector('.js-pickup');
	if ($pickup) {
		const splide = new Splide('.js-pickup-splide', {
			type: 'slide',
			arrows: true,
			pagination: false,
			speed: 600,
			easing: 'cubic-bezier(0.19, 1, 0.22, 1)',
			fixedWidth: '165rem',
			fixedHeight: '150rem',
			gap: '11rem',
			omitEnd: true,
			focus: 0,
			mediaQuery: 'min',
			breakpoints: {
				768: {
					fixedWidth: '280rem',
					fixedHeight: '255rem',
					gap: '28rem',
				},
			},
		});

		splide.mount();
	}

	const $copy = document.querySelector('.js-copy');
	if ($copy) {
		const $head = $copy.querySelector('.js-copy-head');
		const $$char = $head.querySelectorAll('.char');

		ScrollTrigger.create({
			trigger: $head,
			start: 'top 80%',
			end: 'top 80%',
			once: true,
			onEnter: () => {
				gsap.set($head, {
					opacity: 1,
				});

				gsap.fromTo(
					$$char,
					{
						yPercent: 150,
					},
					{
						yPercent: 0,
						duration: 1,
						ease: Ease.gleasing,
						stagger: 0.03,
					}
				);

				$head.classList.add('is-show');
			},
		});
	}

	const $lineup = document.querySelector('.js-lineup');
	if ($lineup) {
		const mm = gsap.matchMedia();

		mm.add('screen and (min-width: 768px)', () => {
			const $mover = $lineup.querySelector('.js-lineup-mover');

			const rect = $mover.getBoundingClientRect();
			const moverRight = rect.right;

			const x = moverRight - window.innerWidth;

			gsap.to($mover, {
				x: -x,
				ease: 'none',
				scrollTrigger: {
					trigger: $lineup,
					start: '300rem top',
					end: '80% bottom',
					scrub: 0.4,
				},
			});
		});

		const $$item = $lineup.querySelectorAll('.js-lineup-item');
		$$item.forEach(($item) => {
			const $en = $item.querySelector('.js-lineup-item-en');
			const $ja = $item.querySelector('.js-lineup-item-ja');

			gsap.set([$en, $ja], {
				yPercent: 120,
				opacity: 1,
			});

			const observer = new IntersectionObserver(
				(entries) => {
					entries.forEach((entry) => {
						if (entry.isIntersecting) {
							gsap.to($en, {
								yPercent: 0,
								duration: 1,
								ease: Ease.gleasing,
							});

							gsap.to($ja, {
								yPercent: 0,
								duration: 1,
								delay: 0.16,
								ease: Ease.gleasing,
							});

							observer.disconnect();
						}
					});
				},
				{
					rootMargin: '-50% -20%',
				}
			);

			observer.observe($item);
		});
	}

	const $real = document.querySelector('.js-real');
	if ($real) {
		const $headline = $real.querySelector('.js-real-headline');

		gsap.set($headline, {
			yPercent: 120,
			opacity: 1,
		});

		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						gsap.to($headline, {
							yPercent: 0,
							duration: 1,
							ease: Ease.gleasing,
						});

						observer.disconnect();
					}
				});
			},
			{
				rootMargin: '-50% -20%',
			}
		);

		observer.observe($real);
	}

	const $works = document.querySelector('.js-works');
	if ($works) {
		const slider = new Splide('.js-works-slider', {
			type: 'loop',
			arrows: true,
			pagination: false,
			speed: 1200,
			gap: '12rem',
			easing: 'cubic-bezier(0.19, 1, 0.22, 1)',
			mediaQuery: 'min',
			updateOnMove: true,
			breakpoints: {
				768: {
					gap: '305rem',
				},
			},
		});

		slider.mount();
	}

	// Mouse Stalker
	MouseStalker();
});

window.addEventListener('load', () => {});
