// npm
import { gsap } from 'gsap';
import Pointer from '../_utils/Pointer';

export default function MouseStalker() {
	new Pointer();

	/**
	 * @type {HTMLElement}
	 */
	const mouse = document.querySelector('.js-stalker');

	const rectSize = mouse.getBoundingClientRect().width;
	let nowRectSize = rectSize;

	let vw = document.documentElement.clientWidth;
	const speed = 0.12;
	const pos = { x: 0, y: 0 };
	const mousePos = { x: 0, y: 0 };

	const xSet = gsap.quickSetter(mouse, 'x', 'px');
	const ySet = gsap.quickSetter(mouse, 'y', 'px');

	let isLocked = false;

	// 画面幅が変更したときに nowRectSize を更新
	window.addEventListener('resize', () => {
		if (vw === document.documentElement.clientWidth) {
			return;
		}
		vw = document.documentElement.clientWidth;
		nowRectSize = mouse.getBoundingClientRect().width;
	});

	gsap.ticker.add(() => {
		if (isLocked) return;

		mousePos.x = Pointer.x - nowRectSize / 2;
		mousePos.y = Pointer.y - nowRectSize / 2;
		// ease
		const ease = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio());

		pos.x += (mousePos.x - pos.x) * ease;
		pos.y += (mousePos.y - pos.y) * ease;

		xSet(pos.x);
		ySet(pos.y);
	});

	// ホバー時の処理
	const FOCUSABLE_ELEMENTS = ['.js-stalker-target'];
	const focusLinkArry = document.querySelectorAll(
		FOCUSABLE_ELEMENTS.join(',')
	);
	focusLinkArry.forEach((el) => {
		const stalkerColor = el.getAttribute('data-stalker-color');

		// hoverが出来る場合
		if (window.matchMedia('(any-hover:hover)').matches) {
			gsap.set(el, {
				cursor: 'none',
			});
		}

		el.addEventListener('mouseenter', () => {
			if (stalkerColor) {
				mouse.dataset.color = stalkerColor;
			}

			document.body.classList.add('is-stalker-hover');
			mouse.classList.add('is-hover');
			el.classList.add('is-hover');
		});

		el.addEventListener('mouseleave', () => {
			isLocked = false;

			if (stalkerColor) {
				mouse.dataset.color = '';
			}

			document.body.classList.remove('is-stalker-hover');
			mouse.classList.remove('is-hover');
			el.classList.remove('is-hover');
		});
	});
}
